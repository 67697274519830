<script>
import { localStorage } from '@/utils'
export default {
  data() {
    return {}
  },
  render() {
    return ''
  },
  beforeRouteEnter(to, from, next) {
    if (to.params.code) {
      localStorage.set('discountCode', to.params.code)
      const redirect = decodeURIComponent(to.query.redirect || '')
      next(vm => {
        redirect ? vm.$router.replace(redirect) : vm.$router.replace('/')
      })
      return
    }
    next(vm => {
      vm.$router.replace('/')
    })
  },
}
</script>
<style lang="less" scoped></style>
